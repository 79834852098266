@import-normalize; 
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500&display=swap');

/* rest of app styles */

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

:root {
  --color-text: #46474D;
  --color-title: #211E1E;
}

img {
  width: 100%;
}

figure {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Satoshi', sans-serif;
  color: var(--color-title);
}

p, li {
  font-family: 'Inter', sans-serif;
  color: var(--color-text);
}

li {
  list-style: none;
}
.App {
  max-width: 75rem;
  min-width: 20rem;
  margin: 0 auto;
}

.container {
  padding: 0 30px;
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden;
}
